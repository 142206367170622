// :root{
//     --color1:rgb(46, 46, 46);
//     --color2:white;
//     --color3:hsl(0, 54%, 38%);
//     --color4:hsl(0, 79%, 43%);
//   }
.left, .right{
    position: relative;
    width: 48%;
}
.timeLineSection,article, .timeLineArticle{
    position: relative;
    margin: 0;
    padding: 0;
}
.timeLineSection::before{
    position: absolute;
    content: '';
    height: 100%;
    width: 5px;
    background-color: var(--color1);
    left: 0px;
    transform:translateX(-50%);
}
.pointText{
    margin: 0px;
    color: var(--color3);
}
.paddingB2{
    padding-bottom: 2rem;
}
.paddingB3{
    padding-bottom: 3rem;
}
article{
    padding-left: 20px;
}
.timeLineArticle::after{
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: var(--color1);
    left: 0;
    top: 1.2rem;
    transform:translate(-50%,-50%)
}
.sectionName{
    font-size: 1.5rem;
    background: var(--color1);
    color: var(--color2);
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.pointTextUnder{
    font-size: 1.1rem;
    margin: 0px;
    padding-left: 7rem;
}
.jobInfo{
    font-size: 1.1rem;
    padding-left: 2rem;
    margin: 0;
    color: darkslategray;
}
.bottomOfTimeLine{
    position: absolute;
    top: 0;
    left:0;
    transform: translateX(-50%) rotateX(180deg);
    position: absolute;
    font-size: 2rem;
    bottom: -0.9rem;
}
.to{
    margin: 0;
    color: var(--color1);
    text-align: right;
    font-style: italic;
}
.half{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.right .iconRelative{
    position: relative;
    bottom:-1rem;
}
.right svg{
    position: relative;
    bottom: -0.2rem;
    color: var(--color4);
}
// .statusBar{
//     position: relative;
//     width: 10px;
//     height: 100%;
//     border-radius: 15px;
//     background-color: var(--color1);
//     overflow: hidden;
// }



@media screen and(max-width:550px){
    .half{
        flex-direction: column;
    }
    .left, .right{
        width: 100%;
    }
}
