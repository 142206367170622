:root{
  --color1:rgb(46, 46, 46);
  --color2:white;
  --color3:hsl(0, 54%, 38%);
  --color4:hsl(0, 79%, 43%);
}
nav,header,main,footer{
  display: flex;
  justify-content: center;
width: 100vw;
}
a{
  text-decoration: none;
  color: var(--color1);
}

nav{
  background: linear-gradient(0deg, var(--color3), var(--color4));
}
nav:hover{
  background: linear-gradient(0deg, #a43030, #d91818);
}
.width{
  padding: 0 1rem;
  width: 100%;
  max-width: 1200px;
  width: 100vw;
}
.navWidth{
  display: flex;
  justify-content: left;
}
nav a{
  position: relative;
  color:var(--color2);
  font-size: 1.3rem;
  padding: 0.6rem 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}
h1{
  cursor:default
}
h1, .icon{
  position: relative;
}
#inputh1{
  position: absolute;
  width: 100%;
  bottom:0;
  left:0;
}
#inputh1::-webkit-slider-runnable-track {
background-color: var(--color2);
}

#inputh1::-webkit-slider-thumb{
  background-color:rgb(98, 175, 98);
}
#inputh1::-ms-thumb{
  background-color:green;
}
#inputh1::-moz-range-thumb {
  background-color:green;
}
h2{
  text-align: center;
  font-size: 3rem!important;
  color: var(--color1);
}
h3{
  position: relative;
  text-align: left;
  padding: 0.3rem 0.6rem;
  margin: 0.5rem 1rem;
  color: var(--color1);
  font-weight: 600;
  font-size: 1.3rem;
  width: fit-content;
}
h3.active{
    font-size: 1.3rem;
    color: var(--color3);
}
h4{
  font-size: 1.3rem;
  text-align: center;
}
section{
  padding: 0rem 1rem;
}
p{
  color: var(--color1);
  font-size: 1.1rem;
}
.tc{
  text-align: center;
}
/* nav a:hover:after{
  content: '';
  position:absolute;
  bottom: 0px;
  left:0%;
  width:100%;
  height:3px;
  background-color: rgb(255, 255, 255);
} */
nav a.active{
  background-color: var(--color2);
  color: var(--color3);
}
/* header */
.headerWidth{
  font-size: 3rem;
  color: var(--color1);
  text-align: center;
  width: auto;
}
.headerWidth h1 span{
  color:var(--color3)
}

/* ikony */
.flex, .icons{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex{
  gap:1rem
}
.flex-col{
  display: flex;
  flex-direction: column;
}
.icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 2rem;
  width: min-content;
}
.icon:hover{
  transition: 0.3s;
  transform: scale(1.2);
}
.iconIMG{
  width: 100px;
  height: 100px;
  border-radius: 15px;
}
.iconI{
  /* position: absolute; */
  bottom: 1rem;
  right: 0;
  width: 1rem;
  text-align: center;
  border-radius: 0.2rem;
  opacity: 0.5;
  color: black;
  text-align: right;
}
.iconInfo{
  position: absolute;
  top: 0;
  left: 0;
}
.iconName{
  margin: 0;
  text-align: center;
  height: 2.2rem;
}
input[type=range]::-webkit-slider-runnable-track {
  background: #751919;
  border-radius: 15px;
  height: 10px;
}
/* intro */
.BtnCvSection{
  background-color: var(--color2);
  border: 1px solid var(--color1);
  border-radius: 20px;
  padding: 0.3rem 0.6rem;
  margin: 0.5rem 1rem;
  color: var(--color3);
  font-weight: 600;
  font-size: 1.2rem;
}
